import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/homepage.sass"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button, Row, Col, Modal } from "antd"
import Img from "gatsby-image/withIEPolyfill"
import PersonWithBand from "../images/home-page-person-with-band.svg"
import DistributorPanic from "../images/home-page-distributor-panic.svg"
import OilTanker from "../images/home-page-oil-tanker.svg"
import DynamicRouting from "../images/homepage-dynamic-routing.svg"
import FuelEfficiency from "../images/homepage-fuel-efficient.svg"
import Scalability from "../images/homepage-scalability.svg"
import FuelOilDrop from "../images/homepage-fuel-oil.svg"
import Lubricants from "../images/homepage-lubricants.svg"
import WasteOil from "../images/homepage-waste-oil.svg"
import WaterTank from "../images/homepage-water.svg"
import MilkTank from "../images/homepage-dairy.svg"
import ContactUs from "../components/contactus"
import useBreakpoint from "../utils/useBreakpoint"
import WEBMvideo from "../images/magnus-vs-video.webm"
import MPEGvideo from "../images/magnus-vs-video.mp4"
import PlayButton from "../images/play-button-white.svg"

const heroSectionImageContainerStyles = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
}

const IndexPage = () => {
  const { breakpoint } = useBreakpoint()
  const isSmallScreen = breakpoint === "sm" || breakpoint === "xs"
  const [isVideoVisible, setVideoVisibility] = React.useState(false)
  const data = useStaticQuery(graphql`
    query {
      heroSectionImage: file(
        relativePath: { eq: "hero-section-device-image.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoImage: file(relativePath: { eq: "magnus-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      solutionsPreview: file(
        relativePath: { eq: "homepage-platform-introduction-preview.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const openVideo = () => {
    setVideoVisibility(true)
  }
  const hideVideo = () => {
    setVideoVisibility(false)
  }
  const heroSectionImageStyles = React.useMemo(
    () => ({
      width: "auto",
      minWidth: "80vw",
      top: isSmallScreen ? "-12%" : 0,
      right: isSmallScreen ? undefined : "-22.0%",
      left: isSmallScreen ? "-15%" : "initial",
      width: "auto",
      height: isSmallScreen ? "90%" : "100%",
    }),
    [isSmallScreen]
  )
  const heroSectionClasses = React.useMemo(() => {
    const baseClasses = ["homepage-hero-section"]
    if (isSmallScreen) {
      baseClasses.push("homepage-hero-section-mobile")
    }
    return baseClasses.join(" ")
  }, [isSmallScreen])
  return (
    <Layout darkHeader>
      <SEO title="Home" />
      <section className={heroSectionClasses}>
        <Modal
          centered
          className="video-modal-homepage-hero-section"
          visible={isVideoVisible}
          destroyOnClose
          onCancel={hideVideo}
          title=" "
          footer={null}
          visible={isVideoVisible}
          width="95%"
        >
          <video
            loop
            controls
            autoPlay
          >
            <source src={WEBMvideo} type="video/webm" />
            <source src={MPEGvideo} type="video/mp4" />
          </video>
        </Modal>
        <Img
          style={heroSectionImageContainerStyles}
          imgStyle={heroSectionImageStyles}
          fluid={data.heroSectionImage.childImageSharp.fluid}
          alt="hero-section"
        />
        <div className="homepage-hero-section-text">
            <h1 className="homepage-hero-section-text-title">MAGNUS</h1>
            <h2 className="homepage-hero-section-text-sub-title">
              RADAR MONITORS
            </h2>
            <h3 className="homepage-hero-section-text-description">
              World’s first battery driven remote radar level monitor
            </h3>
            <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent:  isSmallScreen ? 'space-between' : undefined }}>
            <Link to="/solutions/magnus-monitor">
              <Button type="primary" shape="round">
                Learn More
              </Button>
            </Link>
            <span
              onClick={openVideo}
              className="homepage-hero-section-text-action"
            >
              <img src={PlayButton} alt="play-video" />
              <p>See it in action</p>
              </span>
              </span>
        </div>
      </section>
        <section className="homepage-content-issue-highlights">
          <h1 className="homepage-content-issue-highlights-title">
            FEELING SIDETRACKED?
          </h1>
          <Row
            gutter={[50, 70]}
            className="homepage-content-issue-highlights-items"
            align="middle"
            justify="space-around"
          >
            <Col>
              <div className="homepage-content-issue-highlights-item">
                <div className="homepage-content-issue-highlights-item-image">
                  <img src={PersonWithBand} alt="visibility" />
                </div>
                <p className="homepage-content-issue-highlights-item-description">
                  No visibility on your container assets.
                </p>
              </div>
            </Col>
            <Col>
              <div className="homepage-content-issue-highlights-item">
                <div className="homepage-content-issue-highlights-item-image">
                  <img src={OilTanker} alt="efficient-service" />
                </div>
                <p className="homepage-content-issue-highlights-item-description">
                  Inefficient Servicing, multiple trips.
                </p>
              </div>
            </Col>
            <Col>
              <div className="homepage-content-issue-highlights-item">
                <div className="homepage-content-issue-highlights-item-image">
                  <img src={DistributorPanic} alt="no-panic-distribution" />
                </div>
                <p className="homepage-content-issue-highlights-item-description">
                  Panic situation = Emergency Call Outs
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <section className="homepage-content-platform-introduction">
          <Row align="middle" justify="center">
            <Col xs={2} sm={2} md={1} lg={1} xl={1}>
              <div className="logo-image-container">
                <Img
                  style={{ position: "initial" }}
                  fluid={data.logoImage.childImageSharp.fluid}
                  alt="magnus-analytics-dashboard-preview-1"
                />
              </div>
            </Col>
            <Col>
              <p className="logo-text">magnus.&nbsp;platform</p>
            </Col>
          </Row>
          <h1 className="logo-catchphrase">
            An advanced platform for outstanding hardware.
          </h1>
          <p className="homepage-content-platform-introduction-description">
            We use AI and machine learning to manage our innovative radar level
            monitors and generate actionable insights for your business.&nbsp;
            <Link to="solutions/overview">read more</Link>
          </p>
          <Img
            style={{ marginTop: 85 }}
            fluid={data.solutionsPreview.childImageSharp.fluid}
            alt="hero-section"
          />
        </section>
      <section className="homepage-business-improvement-highlights-section">
        <div className="homepage-business-improvement-highlights-section-content">
            <h1 className="homepage-business-improvement-highlights-section-content-title">
              Put your business on the right road!
            </h1>
            <Row
              gutter={[isSmallScreen ? 0 : 20, 40]}
              justify="space-between"
              className="homepage-business-improvement-highlights-section-content-info"
            >
              <Col
                className="homepage-business-improvement-highlights-section-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div className="homepage-business-improvement-highlights-section-content-info-item">
                  <img src={DynamicRouting} alt="dynamic-routing" />
                </div>
                <p className="homepage-business-improvement-highlights-section-content-info-description">
                  DYNAMIC ROUTING
                </p>
                <p className="homepage-business-improvement-highlights-section-content-info-details">
                  Increased operational efficiency via data analytics
                </p>
              </Col>
              <Col
                className="homepage-business-improvement-highlights-section-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div className="homepage-business-improvement-highlights-section-content-info-item">
                  <img src={Scalability} alt="scalability" />
                </div>
                <p className="homepage-business-improvement-highlights-section-content-info-description">
                  SCALABLE
                </p>
                <p className="homepage-business-improvement-highlights-section-content-info-details">
                  Cost effective and adaptable from 10’s - 1000’s
                </p>
              </Col>
              <Col
                className="homepage-business-improvement-highlights-section-content-info-wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div className="homepage-business-improvement-highlights-section-content-info-item">
                  <img src={FuelEfficiency} alt="fuel-efficiency" />
                </div>
                <p className="homepage-business-improvement-highlights-section-content-info-description">
                  FUEL EFFICIENT
                </p>
                <p className="homepage-business-improvement-highlights-section-content-info-details">
                  Reduction in emissions of CO2 protects the environment
                </p>
              </Col>
            </Row>
        </div>
      </section>
        <section className="homepage-discover-cta">
          <p className="homepage-discover-cta-description">
            Magnus helps further improve the way an Oil distributor does
            business.
          </p>
          <Link to="/industries/oil-distribution">
            <Button className="discover-cta" type="round">
              Discover How
            </Button>
          </Link>
        </section>
        <section className="homepage-serviceable-industries">
          <h1 className="homepage-serviceable-industries-title">
            SERVICEABLE INDUSTRIES
          </h1>
          <Row gutter={[75, 105]} align="middle" justify="center">
            <Col>
                <div className="homepage-serviceable-industries-item">
                  <div className="homepage-serviceable-industries-item-image">
                    <img src={FuelOilDrop} alt="fuel-oil-industry" />
                  </div>
                  <p className="homepage-serviceable-industries-item-description">
                    Fuel Oil
                  </p>
                </div>
            </Col>
            <Col>
                <div className="homepage-serviceable-industries-item">
                  <div className="homepage-serviceable-industries-item-image">
                    <img src={Lubricants} alt="lubricant-industry" />
                  </div>
                  <p className="homepage-serviceable-industries-item-description">
                    Lubricants & AdBlue
                  </p>
                </div>
            </Col>
            <Col>
                <div className="homepage-serviceable-industries-item">
                  <div className="homepage-serviceable-industries-item-image">
                    <img src={WasteOil} alt="waste-oil-industry" />
                  </div>
                  <p className="homepage-serviceable-industries-item-description">
                    Waste Oil
                  </p>
                </div>
            </Col>
            <Col>
                <div className="homepage-serviceable-industries-item">
                  <div className="homepage-serviceable-industries-item-image">
                    <img src={MilkTank} alt="milk-tank-dairy" />
                  </div>
                  <p className="homepage-serviceable-industries-item-description">
                    Dairy
                  </p>
                </div>
            </Col>
            <Col>
                <div className="homepage-serviceable-industries-item">
                  <div className="homepage-serviceable-industries-item-image">
                    <img src={WaterTank} alt="water-tank" />
                  </div>
                  <p className="homepage-serviceable-industries-item-description">
                    Water
                  </p>
                </div>
            </Col>
          </Row>
        </section>
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
